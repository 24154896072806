<template>
	<el-dialog class="service" :visible.sync="showService" width="1200px" @close='closeDialog' :append-to-body="true">
		<div class="service-box">
			<div class="service-box-left" v-if="member">
				<div class="top_total">消息{{unread_total_num ? '（'+unread_total_num+'）' : ''}}</div>
				<div class="search_area">
					<div class="iconfont1 icon-sousuo" @click.stop="searchChatMember('click')"></div>
					<input type="text" placeholder="" v-model="search_name" @input="searchChatMember()">
				</div>
				<div class="people-box">
					<div @click.stop="chat_group_id == item.chat_group_id?'':selectChat(item)" v-for="(item,index) in chatGroupList" :key="index"
					 :class="{active:chat_group_id == item.chat_group_id}" class="people-item">
						<div class="shop-info">
							<div class="shop_img" :style="{backgroundImage:'url('+$util.img(item.to_headimg?item.to_headimg:defaultHeadImage)+')'}"></div>
							<div class="msg_info">
								<div class="text-hidden">{{item.to_nickname}}
									<div style="font-size: 12px;" class="time">{{ $util.timeStampTurnTime(item.update_time) | Time }}</div>
								</div>
								<div class="last_content">
									<!-- <div class="msg">{{item.latest_msg}}</div> -->
									<div class="msg" v-html="item.latest_msg"></div>
									<div class="num" v-if="item.unread_num">{{item.unread_num}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 右半边 -->
			<div class="service-box-right">
			<template v-if="chat_group_id">
				<div class="chat_member">{{chat_group_member}}</div>
				<div class="service-box-right-top test-1">
					<div class="content" id="content">
						<div class="text-center" v-if="contentEmpty">没有聊天记录了</div>
						<div v-else class="time text-center" style="cursor:pointer;" @click="getMessageList">点击加载</div>
				
						<div v-for="(item,index) in messageList" :key="index">
							<div v-if="item.isItMe" class="message my-message">
								<div>
									<!-- <div class="word-message" >{{item.content}}</div> -->
									<div class="word-message" v-html="item.content"></div>
								</div>
								<div class="headimg" :style="{backgroundImage:'url('+$util.img(member.headimg?member.headimg:defaultHeadImage)+')'}">
								</div>
							</div>
							<!-- 对方发起的消息 -->
							<div v-else-if="item.isItMe === false" class="message your-message">
								<div class="headimg" :style="{backgroundImage:'url('+$util.img(item.logo?item.logo:defaultHeadImage)+')'}">
								</div>
								<div>
									<!-- <div class="word-message your-word-message">{{item.content}}</div> -->
									<div class="word-message your-word-message" v-html="item.content"></div>
								</div>
							</div>
						</div>
						<div style="height:40px;"></div>
					</div>
				</div>
				<div class="service-box-right-bottom">
					<!-- <textarea class="input-panel" @keydown.enter="sendMessage()" v-model="message"></textarea> -->
					<div class="input-panel" ref="msgInputContainer" @keydown.enter.exact="sendMessage()" contenteditable="true" spellcheck="false" @input="input($event)"></div>	
					<el-button class="send-btn" @click="sendMessage()">发送</el-button>
				</div>
			</template>
			<template v-else>
			</template>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Config from "@/utils/config"
	import goodsItem from "./goodsItem"
	import orderItem from "./orderItem"
	import {
		bindServicer,
		messageList,
		hasServicers,
		closeMessage,
		currStore
	} from "@/api/message"
	import { getChatGroupId, getChatGroupList, getChatMessageList, online, offline,readMessage, sendMessage } from "@/api/resource"
	import {
		mapGetters
	} from "vuex"
	import emjoy from "@/utils/emjoy"
	export default {
		components: {
			goodsItem,
			orderItem
		},
		props: {
			toUid: {
				type: [String,Number],
				default: ''
			},
		},
		data() {
			return {
				isFirstInit: true,
				showEmoji: false, //是否显示表情
				emjoyList: emjoy.emjoyList,
				showService: false,
				search_text: "",
				websock: null,
				messageList: [], //聊天记录
				message: "", //发送内容
				image: "", //发送内容
				sendSwitch: true, //防止重复发送
				page: 1, //数据页码
				total: 0, //数据总数
				getSwitch: true, //防止重复拉去列表，节流
				contentEmpty: false, //是否有聊天记录
				chatGroupList: [], //店铺列表
				canSend: true,
				timeoutObj: null,
				uploadActionUrl: Config.baseUrl + '/api/upload/chatimg', //表情图标
				is_first: true, //第一次进入模拟点击
				serve_info: {
					name: '',
					logo: ''
				} ,//客服的个人信息
				chat_group_id:0,
				chat_group_member:'',
				start_message_id:0,
				first_message_id:0,
				client_id:'',
				unread_total_num:0,
				search_name:'',
				original_group_list:[],
			}
		},
		filters: {
			Time: function(time) {
				var date2 = new Date();
				var year1 = parseInt(time.substr(0, 4));
				var month1 = parseInt(time.substr(5, 2));
				var day1 = parseInt(time.substr(8, 2));
				var year2 = date2.getFullYear();
				var month2 = date2.getMonth() + 1;
				var day2 = date2.getDate();
				var date1 = new Date(year1, month1 - 1, day1);
				var date2 = new Date(year2, month2 - 1, day2);
				var timeX = date1.getTime() - date2.getTime();
				if (Math.abs(timeX) < 24 * 60 * 60 * 1000) {
					return "今天" + time.substr(11, 5);
				} else if (timeX > 0 && timeX < 48 * 60 * 60 * 1000) {
					return "明天" + time.substr(11, 5);
				} else if (timeX < 0 && timeX > 0 - 48 * 60 * 60 * 1000) {
					return "昨天" + time.substr(11, 5);
				} else {
					return time.substr(5, 11).replaceAll('-','/');
					//					let last_time = time.substr(0, 10);
					//					last_time = last_time.replace(/-/g, '/');
					//					return last_time
				}
			}
		},
		watch: {
			showService(curr) {
				if (!curr && this.websock) {
					this.websock.close()
				}
			},
			groupId(val) {
				this.group_id = this.groupId;
			},
			search_name(nval){
				if(nval==''){
					console.log('/////////-------------------')
					this.chatGroupList = this.original_group_list;
				}
			}
		},
		mounted() {
			
		},
		computed: {
			...mapGetters(["token", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit", "locationRegion",
				"member"
			])
		},
		// 如果之前有连接，切断链接
		beforeDestroy() {
			clearInterval(this.timeoutObj)
			offline({client_id:this.client_id}).then((res) => {
				if (res.code == 0 && this.websock) {
					this.websock.close()
				}
			})
		},
		methods: {
			searchChatMember(mode=''){
				if(mode=='click' && !this.search_name){
					this.$message.error('请输入搜索名称')
					return;
				}
				var list = [];
				this.chatGroupList.forEach((item,index)=>{
					if(item.to_nickname.indexOf(this.search_name)!=-1){
						list.push(item);
					}
				})
				this.chatGroupList = list;
			},
			calcTotalUnreadNum(){
				var unread_total_num = 0;
				this.chatGroupList.forEach(ites => {
					unread_total_num += Number(ites.unread_num);
				})
				this.unread_total_num = unread_total_num;
			},
			initData(){
				if(this.toUid){
					getChatGroupId({
						to_uid:this.toUid
					})
					.then(res=>{
						if(res.code>=0){
							this.chat_group_id = res.data;
							this.getChatGroupList();
						}
					})
				}else{
					this.getChatGroupList();
				}
			},
			// 处理消息中的数据
			dealMessage(val) {
				return emjoy.stringToEmjoy(val)
			},
			// 点击表情，添加表情
			sendEmoji(index, item) {
				this.showEmoji = false
				this.$refs.msgInputContainer.focus()
				const imgSrc = this.$util.img(item)
				const imgTag = `<img src="${imgSrc}" width="20" height="20">`;
				document.execCommand("insertHTML", false, imgTag);
			},
			// 展示弹窗
			show() {
				this.is_first = true
				this.chat_group_id = 0;
				this.start_message_id = 0;
				this.$nextTick(()=>{
					this.initData();
				})
				this.showService = true
				this.initWebSocket();
			},
			// 选择联系人
			selectChat(item) {
				this.page = 1;
				this.contentEmpty = false;
				this.chat_group_id = item.chat_group_id;
				this.chat_group_member = item.to_nickname;
				this.start_message_id = 0;
				this.first_message_id = item.first_message_id;
				// 消除未读数
				this.chatGroupList.forEach(ites => {
					if (ites.chat_group_id == item.chat_group_id) {
						item.unread_num = 0;
						this.isRead(ites)
					}
				})
				this.calcTotalUnreadNum();
				this.messageList = [];
				// this.closeDialog(); //关闭旧链接
				// this.initWebSocket(); //打开新衔接
				this.getMessageList() //获取聊天信息
				if(this.$refs.msgInputContainer) this.$refs.msgInputContainer.innerHTML = '' //文本重置
			},
			//标识已读未读
			isRead(item) {
				readMessage({
					chat_group_id:item.chat_group_id
				}).then(res => {
					if(res.code>=0){
					}else{
						this.$message.error(res.message)
					}
				}).catch(err => {})
			},
			// 滚动到底部
			scrollBottom() {
				var div = document.getElementById("content")
				if(div){
					setTimeout(() => {
						div.scrollTop = div.scrollHeight;
					}, 0)
				}
				
			},
			// 获取聊天记录
			getMessageList() {
				if(this.messageList.length){
					this.start_message_id = this.messageList[0].id;
				}
				getChatMessageList({
					message_num:10,
					chat_group_id:this.chat_group_id,
					start_message_id:this.start_message_id
				}).then((res) => {
					const {
						code,
						data,
						messge
					} = res
					if (code == 0) {
						let messageList = []
						let arr = res.data
						arr.forEach((item) => {
							let obj = {};
							obj.content = item.content;
							obj.isItMe = item.is_self == 1 ? true : false //自己的消息还是对面发来的消息
							obj.id = item.id;
							messageList.push(obj)
						})
						this.messageList = messageList.concat(!this.contentEmpty ? this.messageList : [])
						if (this.page == 1) {
							this.scrollBottom()
						}
						if( (!this.messageList.length) || (this.messageList[0].id <= this.first_message_id) ) {
							this.contentEmpty = true
						}else{
							this.page += 1;
						}
					}
				})
			},
			// 加入评论信息
			joinMessage(isItMe = "mine", contentType = "string") {
				let data = {
					isItMe: isItMe == "mine" ? true : false,
					contentType: contentType
				}
				if (contentType == "string") {
					data.content = this.message
				}
				if (contentType == "goods") {
					data.sku_id = this.skuId
					data.canSend = false
				}
				if (contentType == "order") {
					data.order_id = this.orderId
					data.canSend = false
				}
				if (contentType == "image") {
					data.content = this.image;
				}
				this.messageList.push(data);
				this.scrollBottom();
			},
			// 输入文字时
			input(event) {
				let str = this.$refs.msgInputContainer.innerHTML
				var a = str.replace(/<\s?img.*?src\s*=\s*[\"|\']?(.*?)[\"|\']\s.*?>/ig, '0');
				
			},
			// 发送消息
			sendMessage(type = "string", index = -1) {
				if (index != -1) this.messageList.splice(index, 1)
				if (!this.sendSwitch) return
				this.sendSwitch = false
				let data = {
					chat_group_id:this.chat_group_id
				}
				let _content = this.$refs.msgInputContainer.innerHTML
				let _message = _content.replace(/<div>/g, '');
				let _message2 = _message.replace(/<\/div>/g, '');
				let _message3 = _message2.replace(/<br>/g, '');
				this.message = _message3;
				
				if (type == "string") {
					// console.log(!this.$refs.msgInputContainer.innerHTML.replaceAll('&nbsp;','').trim(),JSON.stringify(this.$refs.msgInputContainer.innerHTML.replaceAll('&nbsp;','').trim()),56)
					// console.log(this.message,'***')
					// if(!this.$refs.msgInputContainer.innerHTML.replaceAll('&nbsp;','').trim()){
					// 	this.sendSwitch = true;
					// 	this.message = '';
					// 	this.$refs.msgInputContainer.innerHTML = ''
					// 	this.$message({
					// 		message: "不能发送空白内容11",
					// 		type: "warning"
					// 	})
					// 	console.log(!this.$refs.msgInputContainer.innerHTML.replaceAll('&nbsp;','').trim(),JSON.stringify(this.$refs.msgInputContainer.innerHTML.replaceAll('&nbsp;','').trim()),56)
					// 	return;
					// }
					// console.log(this.message,'```',this.message.replaceAll('&nbsp;',''),510)
					// this.sendSwitch = true
					// return;
					if (!this.message.trim()) {
					// if (!this.message.replaceAll('&nbsp;','').trim()) {
						this.sendSwitch = true;
						this.$message({
							message: "不能发送空白内容",
							type: "warning"
						})
						return
					}
					Object.assign(data, {
						content: this.message,
						type:'text'
					})
				}
				this.joinMessage("mine", type)
				sendMessage(data)
					.then((res) => {
						this.sendSwitch = true
						this.message = ""
						this.$refs.msgInputContainer.innerHTML = ''
						this.getChatGroupList();
					})
				this.scrollBottom();
			},
			// 初始化socket
			initWebSocket() {
				//初始化weosocket
				// console.log(Config.webSocket)
				const wsuri = Config.webSocket
				this.websock = new WebSocket(wsuri)
				this.websock.onopen = this.websocketonopen
				this.websock.onmessage = this.websocketonmessage
				this.websock.onerror = this.websocketonerror
				this.websock.onclose = this.websocketclose
			},
			websocketonopen() {
				//连接建立之后执行send方法发送数据
				console.log("连接建立")
			},
			websocketonerror() {
				//连接建立失败重连
				console.log("重连")
				this.initWebSocket()
			},
			getChatGroupList(){
				console.log('获取联系人')
				getChatGroupList({
					curr_chat_group_id:this.chat_group_id
				})
				.then(res=>{
					if(res.code>=0){
						this.chatGroupList = res.data;
						this.original_group_list = res.data;
						this.chatGroupList.forEach(ites => {
							if(ites.chat_group_id == this.chat_group_id){
								ites.unread_num = 0;
								this.isRead(ites)
							}
						})
						if(this.is_first && this.toUid){
							this.is_first = false;
							this.chatGroupList.forEach(item=>{
								if(item.chat_group_id == this.chat_group_id) this.selectChat(item)
							})
						}else{
							this.calcTotalUnreadNum();
						}
					}
				})
			},
			// 接受消息
			websocketonmessage(e) {
				//数据接收
				let data = JSON.parse(e.data);
				this.pingInterval = Config.pingInterval
				if (data.type == "close" && this.websock) {
					this.websock.close()
				}
				clearInterval(this.timeoutObj)
				this.reset()
				// 如果是第一次绑定
				if (data.type == "init") {
					this.client_id = data.data.client_id;
					this.messageList = [];
					online({
							client_id: this.client_id,
						})
					.then((res) => {
					})
					.catch((err) => {
					})
				} else if (data.type == "chat") {
					if(data.data.chat_group_id == this.chat_group_id){
						var contentData = {
							isItMe: false
						};
						contentData.content = data.data.content;
						contentData.time = this.$util.timeStampTurnTime(data.data.send_time);
						contentData.logo = data.data.headimg;
						contentData.nickname = data.data.nickname;
						contentData.contentType = 'string'
						// this.chatGroupList.forEach(ites => {
							
						// })
						this.getChatGroupList()
						this.messageList.push(contentData);
						this.scrollBottom();
					}else{
						this.getChatGroupList();
					}
				}
			},
			// 检测心跳reset
			reset() {
				console.log("检测心跳")
				console.log(this.timeoutObj, 'this.timeoutObj')
				clearInterval(this.timeoutObj)
				this.start() // 启动心跳
			},
			// 启动心跳 start
			start() {
				if (Config.pingInterval == 0) return;
				console.log("启动心跳")
				this.timeoutObj = setInterval(() => {
					console.log('检测...')
					this.websock.send("ping")
				}, this.pingInterval)
			},
			websocketsend(Data) {
				//数据发送
				this.websock.send(Data)
			},
			websocketclose(e) {
				//关闭
				console.log("断开连接", e)
			},
			closeDialog() {
				clearInterval(this.timeoutObj)
				console.log('关闭链接')
				this.websock.close()
				
				// console.log(this.websock, '关闭连接')
				if (this.websock) {
					offline({
						client_id:this.client_id
					}).then((res) => {
						// console.log(res, 'res')
						if (res.code == 0) {
							clearInterval(this.timeoutObj)
						}
					})
				}
			},
		}
	}
</script>

<style>
	.kefu .el-dialog__header {
		padding: 0 !important;
	}

	.kefu .el-dialog__headerbtn {
		top: 8px !important;
	}

	.kefu .el-dialog__body {
		padding: 0 !important;
		min-width: 800px;
	}

	.kefu .el-dialog {
		min-width: 860px !important;
	}
	.service .el-dialog{
		border-radius: 10px;
	}
</style>

<style lang="scss" scoped="">
	html,
	body {
		overflow-y: scroll;
	}

	html,
	body {
		overflow: scroll;
		min-height: 101%;
	}

	html {
		overflow: -moz-scrollbars-vertical;
	}

	.service {
		min-width: 800px;
		.el-dialog{
			border-radius: 10px;
		}
		.header-box {
			color: white;
			align-items: center;
			padding: 10px 15px;
			border-bottom: 1px solid #eee;
			font-size: 15px;
			padding-left: 260px;
			display: flex;
			align-items: center;
			justify-content: center;

			.header-logo {
				position: absolute;
				top: 10px;
				left: 15px;
				display: flex;
				align-items: center;

				.header-img {
					margin-right: 10px;
					background-color: white;
					border-radius: 50%;
					width: 26px;
					height: 26px;
				}
			}

			.online {
				line-height: 1.3;
				margin-left: 5px;
				padding: 2px 6px;
				background-color: white;
				border-radius: 10px;
				font-size: 10px;
				display: flex;
				color: #000;
				align-items: center;

				.block {
					margin-right: 4px;
					border-radius: 50%;
					width: 6px;
					height: 6px;
					background-color: rgba(#70ed3a, 1);
				}
			}

			.outline {
				line-height: 1.3;
				margin-left: 5px;
				padding: 2px 6px;
				background-color: white;
				border-radius: 10px;
				font-size: 10px;
				display: flex;
				color: #000;
				align-items: center;

				.block {
					margin-right: 4px;
					border-radius: 50%;
					width: 6px;
					height: 6px;
					background-color: rgba(#b2b2b2, 1);
				}
			}

		}
	}

	.operation {
		display: flex;
		align-items: center;
		padding: 10px 24px;

		img {
			margin-right: 10px;
		}

		.emjoy {
			width: 20px;
			height: 20px;
		}
	}

	.service-box {
		min-width: 800px;
		display: flex;
		height: 100%;
		border-radius: 10px;
		.service-box-left {
			width: 356px;
			padding: 32px 0 14px;
			display: flex;
			flex-direction: column;
			
			.top_total{
				color: #30373D;
				font-size: 15px;
				line-height: 15px;
				margin: 0 20px 20px;
			}
			.search_area{
				display: flex;
				align-items: center;
				margin-left: 20px;
				margin-right: 20px;
				background-color: #F7F8FB;
				padding: 7px 10px;
				border-radius: 20px;
				margin-bottom: 20px;
				.iconfont1{
					font-size: 12px;
					line-height: 12px;
					color: #707991;
					font-weight: 600;
					cursor: pointer;
				}
				input{
					color: #707991;
					font-size: 9px;
					line-height: 10px;
					outline: none;
					border: none;
					padding: 0;
					background-color: transparent;
					flex: 1;
					margin-left: 10px;
					font-family: "PingFang SC";
				}
			}
			.service-box-left-top {
				background-color: $base-color;
				padding: 10px;

				.member-info {
					display: flex;

					.headimg {
						width: 50px;
						height: 50px;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.member-nickname {
						flex: 1;

						div {
							color: #fff;
						}
					}
				}

				.search-box {
					display: flex;
					background-color: #fff;
					align-items: center;
					height: 24px;
					border-radius: 4px;
					padding: 0 5px;

					input {
						padding: 0;
						outline: none;
						border: 0;
						margin-left: 5px;
						height: 100%;
						line-height: 24px;
					}
				}
			}

			.people-box {
				flex: 1;
				overflow-y: auto;

				.people-item {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 20px;
					justify-content: center;
					margin-bottom: 10px;

					.shop-info {
						position: relative;
						display: flex;
						align-items: center;
						overflow: hidden;
						flex: 1;

						.shop_img {
							width: 41px;
							height: 41px;
							max-width: 41px;
							margin-right: 10px;
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
							border-radius: 50%;
						}

						>div {
							flex: 1;
							overflow: hidden;
							height: 41px;
							&.msg_info{
								display: flex;
								flex-direction: column;
								justify-content: space-between;
							}
							.text-hidden{
								display: flex;
								justify-content: space-between;
								align-items: center;
								color: #30373D !important;
								font-weight: 600;
								font-size: 15px;
								line-height: 15px;
								.time{
									color: rgba(48, 55, 61, 0.50);
									line-height: 10px;
									font-size: 10px;
								}
							}
							.last_content {
								display: flex;
								justify-content: space-between;
								align-items: center;
								overflow: hidden;
								.msg {
									color: rgba(48, 55, 61, 0.50);
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									flex: 1;
									display: block;
									font-size: 13px;
									line-height: 13px;
									img {
										width: 5px !important;
										height: 5px !important;
									}
								}

								.num {
									display: flex;
									align-items: center;
									color: #fff;
									background-color: #78E378;
									height: 14px;
									line-height: 14px;
									min-width: 14px;
									padding: 0 3px;
									box-sizing: border-box;
									border-radius: 10px;
									font-size: 10px;
									justify-content: center;
								}
							}
						}

						.online-people {
							position: absolute;
							bottom: 0;
							left: 26px;
							border-radius: 50%;
							background-color: #70ed3a;
							width: 10px;
							height: 10px;
						}

						.outline-people {
							position: absolute;
							bottom: 0;
							left: 26px;
							border-radius: 50%;
							background-color: #b2b2b2;
							width: 10px;
							height: 10px;
						}
					}

					&.active {
						background-color: #F7F8FB;
					}
				}
			}



			.people-box::-webkit-scrollbar {
				/*滚动条整体样式*/

				width: 5px;
				/*高宽分别对应横竖滚动条的尺寸*/

				height: 1px;

			}

			.people-box::-webkit-scrollbar-thumb {
				/*滚动条里面小方块*/

				border-radius: 20px;

				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

				background: #BFBFBF;

			}

			.people-box::-webkit-scrollbar-track {
				/*滚动条里面轨道*/

				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

				border-radius: 10px;

				background: white;

			}




		}

		.service-box-right {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			border-left: 1px solid rgba(48, 55, 61, 0.1);
			padding-bottom: 20px;
			.chat_member{
				padding: 30px 20px 22px;
				color: #30373D;
				font-size: 15px;
				line-height: 15px;
				font-weight: 600;
				border-bottom: 1px solid rgba(48, 55, 61, 0.1);
			}
			.service-box-right-top {
				flex: 1;
				overflow-y: auto;
				width: 100%;
				// width: 102.5%;

				.shop-name {
					display: flex;
					height: 60px;
					align-items: center;
					// border-bottom: 1px solid #eee;

					img {
						width: 40px;
						height: 40px;
						margin-right: 5px;
						margin-left: 10px;
					}
				}

				.content {
					padding-top: 10px;
					height: 100%;
					overflow-y: auto;
					display: flex;
					flex-direction: column;

					&>div {
						margin: 10px 0 0;

						.onlineStatus {
							margin: auto
						}
					}

					.last-tip {
						color: #a1a6af;
						position: relative;

						&::before {
							content: "";
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							width: 70px;
							height: 1px;
							background: #a1a6af;
							left: -80px;
						}

						&::after {
							content: "";
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							width: 70px;
							height: 1px;
							background: #a1a6af;
							right: -80px;
						}
					}

					.time {
						color: #a1a6af;
					}

					.message {
						display: flex;
						justify-content: flex-end;
						align-items: flex-start;

						.my-nickname {
							text-align: right;
							color: #909399;

							span {
								padding-left: 10px;
								font-size: 10px;
							}
						}

						.headimg {
							width: 32px;
							height: 32px;
							margin: 0 10px 0 20px;
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
							border-radius: 50%;
						}

						&.your-message {
							justify-content: flex-start;

							.my-nickname {
								text-align: left;
							}
						}
					}

					.word-message {
						background-color: #78E378;
						color: #333;
						position: relative;
						padding: 10px;
						max-width: 450px;
						width: fit-content;
						border-radius: 10px 0 10px 10px;
						line-height: 1.2;
						::v-deep img {
							max-width: 150px;
						}

						::v-deep .el-image-viewer__close {
							color: #fff;
						}

						// &::after {
						// 	content: "";
						// 	position: absolute;
						// 	width: 10px;
						// 	height: 10px;
						// 	background-color: #89BCFF;
						// 	top: 15px;
						// 	right: -5px;
						// 	transform: rotateZ(45deg);
						// }

						&.your-word-message {
							background-color: #F7F8FB;
							color: #333;
							border-radius: 0 10px 10px 10px;
							&::after {
								left: -5px;
								background-color: #F0F0F0;
								transform: rotateZ(45deg);
							}

							img {
								max-width: 150px;
							}
						}
					}
				}
			}
			.content::-webkit-scrollbar {
				width: 6px;
			}

			.content::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background: #b5b5b5;
			}

			.content::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				border-radius: 10px;
				background: #EDEDED;
			}
			.service-box-right-top::-webkit-scrollbar {
				width: 10px;
				display: none;
			}

			.service-box-right-top::-webkit-scrollbar-thumb {
				border-radius: 10px;
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				background: #535353;
			}

			.service-box-right-top::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
				border-radius: 10px;
				background: #EDEDED;
			}

			.service-box-right-bottom {
				height: 80px;
				position: relative;
				background-color: #F7F8FB;
				border-radius: 10px;
				margin: 0 20px;
				.num-box {
					position: absolute;
					bottom: 13px;
					right: 68px;
					text-align: right;
					width: 60px;
					color: #909399;
				}

				.send-btn {
					position: absolute;
					bottom: 10px;
					right: 10px;
					background-color: #93979c;
					color: #fff;
					font-size: 15px;
					line-height: 14px;
					font-weight: 600;
					border-radius: 5px;
					padding: 6px 42px !important;
					font-family: "PingFang SC";
				}

				.emoji-btn {
					position: absolute;
					bottom: 10px;
					left: 10px;
				}
			}
		}
	}



	.input-panel {
		width: 100%;
		height: 80%;
		padding: 6px 10px;
		outline: none;
		box-sizing: border-box;
		line-height: 1.3;
		overflow-y: scroll;
	}

	.input-panel::-webkit-scrollbar {
		display: none;
	}

	.service {
		::v-deep .el-dialog__header {
			padding: 0;
		}

		::v-deep .el-dialog__headerbtn {
			top: 17px !important;
		}

		::v-deep .el-dialog__body {
			height: 640px;
		}

		::v-deep .el-dialog__headerbtn .el-dialog__close {
			color: white;
		}
	}

	.text-center {
		text-align: center;
		line-height: 1;
	}

	.text-hidden {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.text-hidden-two-row {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.emoji-box {
		background-color: #fff;
		z-index: 9999;
		position: absolute;
		height: 90px;
		overflow: hidden scroll;
		padding-left: 14px;
		top: -90px;

		img {
			margin: 5px;
		}
	}

	.emoji-box::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 5px;
	}

	.emoji-box::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 20px;
		// -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: white;
	}

	.emoji-box::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		// -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		background: white;
	}

	.onlineStatus {
		background-color: #6b6c6d;
		text-align: center;
		width: 90px;
		margin: auto;
		font-size: 12px;
		color: #fff;
		border-radius: 6px;
		line-height: 22px;
	}

	::v-deep .upload {
		line-height: 1;

		::v-deep .el-upload {
			line-height: 1;
		}
	}
</style>
