import BasicLayout from "../../layout/basic";

// 其他模块
export default {
	path: "*",
	component: BasicLayout,
	name: "error",
	meta: {
	    module: "index"
	},
	children: [
		{
			path: "*",
			name: "error",
			meta: {
				module: "index"
			},
			component: () => import("@/views/index/error.vue")
		},
	]
}
