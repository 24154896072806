<template>
	<aside class="main-sidebar">
		<div class="fixed_right_bottom">
			<div class="el-icon-circle-plus" @click="toJump('publish')"></div>
			<div class="iconfont1 icon-xihuan" @click="toJump('xihuan')"></div>
			<img class="kefu" src="@/assets/images/newImg/kefu.png" @click="openKeFu()" />
			<img src="@/assets/images/newImg/aside_member.png" @click="toJump('member')" />
		</div>
		<servicerMessage ref="servicerMessage" class="kefu"></servicerMessage>
	</aside>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		shopServiceOpen
	} from "@/api/website.js"
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		components: {
			servicerMessage
		},
		props: {},
		data() {
			return {
				
			}
		},
		computed: {
			...mapGetters(["cartCount", "siteInfo", 'member',"token"]),
			logined: function() {
				return this.member !== undefined && this.member !== "" && this.member !== {}
			}
		},
		created() {
			this.shopServiceOpen()
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll)
			
			if(localStorage.getItem('isKefuAside') && this.token){
				this.$refs.servicerMessage.show();
				localStorage.removeItem('isKefuAside');
			}
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll)
		},
		watch: {},
		methods: {
			openKeFu(){
				if (this.token) {
					this.$refs.servicerMessage.show()
				} else {
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
					setTimeout(()=>{
						localStorage.setItem("isKefuAside",true);
						this.$router.push({path: '/login', query: {redirect: this.$route.fullPath}});
					},800)
				}
			},
			toJump(mode){
				if(!this.token){
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
					var url = '';
					switch (mode){
						case 'publish':
							url = '/cms/publish_info?mode=1';
							break;
						case 'xihuan':
							url = '/member/index?my_current=3&cart_type=collect';
							break;
						case 'member':
							url = '/member/index';
							break;
						default:
							break;
					}
					setTimeout(()=>{
						this.$router.push({path:'/login',query: {redirect: url}});
					},800)
					return;
				}
				switch (mode){
					case 'publish':
						this.$router.pushToTab('/cms/publish_info?mode=1');
						break;
					case 'xihuan':
						this.$router.pushToTab('/member/index?my_current=3&cart_type=collect');
						break;
					case 'member':
						this.$router.pushToTab('/member/index');
						break;
					default:
						break;
				}
			},
			handleScroll() {
				this.visible = window.pageYOffset > 300
			},
			shopServiceOpen() {
				shopServiceOpen().then((res) => {
					if (res.code == 0) {
						if (res.data.type == 'third') {
							this.serverType = res.data.type
							this.serverThird = res.data.third
						} else if (res.data.type == 'system') {
							this.serverType = res.data.type
							this.serverThird = ''
						}
					}
				})
			},
			toTop() {
				let timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop
					let ispeed = Math.floor(-osTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
					this.isTop = true
					if (osTop === 0) {
						clearInterval(timer)
					}
				}, 20)
			},
			// 打开客服弹窗
			showServiceFn() {
				if (this.logined) {
					if (this.serverType == 'third') {
						window.open(this.serverThird, "_blank");
					} else if (this.serverType == 'system') {
						this.hackReset = true;
						this.$refs.servicerMessage.show()
					}
				} else {
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.main-sidebar{
		position: fixed;
		right: 53px;
		bottom: 100px;
		z-index:100;
		.fixed_right_bottom{
			display: flex;
			align-items: center;
			flex-direction: column;
			background-color: #fff;
			border-radius: 50px;
			padding: 22px;
			.el-icon-circle-plus{
				cursor: pointer;
				font-size: 23px;
				line-height: 1;
				color: #999;
				margin-bottom: 23px;
			}
			.iconfont1{
				cursor: pointer;
				font-size: 23px;
				line-height: 1;
				color: #999;
				margin-bottom: 23px;
			}
			img{
				cursor: pointer;
				width: 23px;
				height: 23px;
				display: block;
				&.kefu{
					margin-bottom: 23px;
				}
			}
		}
	}
</style>
