import http from "../utils/http"


export function goodsCategoryLists(params) {
    return http({
        url: "mling/api/goodscategory/lists",
        data: params
    })
}
export function attrDetail(params) {
    return http({
        url: "mling/api/membergoods/getattrdetail",
        data: params
    })
}
export function addGoods(params) {
    return http({
        url: "mling/api/membergoods/addgoods",
        data: params
    })
}
export function editGoods(params) {
    return http({
        url: "mling/api/membergoods/editgoods",
        data: params
    })
}
export function myGoodslist(params) {
    return http({
        url: "mling/api/membergoods/getgoodslist",
        data: params
    })
}
export function myGoodsDetail(params) {
    return http({
        url: "mling/api/membergoods/getgoodsdetail",
        data: params
    })
}
export function onGoods(params) {
    return http({
        url: "mling/api/membergoods/ongoods",
        data: params
    })
}
export function offGoods(params) {
    return http({
        url: "mling/api/membergoods/offgoods",
        data: params
    })
}
export function deleteGoods(params) {
    return http({
        url: "mling/api/membergoods/deletegoods",
        data: params
    })
}
export function categoryList(params) {
    return http({
        url: "mling/api/goodscategory/lists",
        data: params
    })
}
export function goodsList(params) {
    return http({
        url: "mling/api/goods/getgoodslist",
        data: params
    })
}
export function goodsAttrDetail(params) {
    return http({
        url: "mling/api/goodscategory/getAttrDetail",
        data: params
    })
}
export function goodsDetail(params) {
    return http({
        url: "mling/api/goods/getgoodsdetail",
        data: params
    })
}
export function addCollect(params) {
    return http({
        url: "mling/api/goodscollect/add",
        data: params
    })
}
export function deleteCollect(params) {
    return http({
        url: "mling/api/goodscollect/delete",
        data: params
    })
}
export function addCart(params) {
    return http({
        url: "mling/api/cart/add",
        data: params
    })
}
export function deleteCart(params) {
    return http({
        url: "mling/api/cart/delete",
        data: params
    })
}
export function isCollect(params) {
    return http({
        url: "mling/api/goodscollect/iscollect",
        data: params
    })
}
export function isCart(params) {
    return http({
        url: "mling/api/cart/iscart",
        data: params
    })
}
export function orderCreate(params) {
    return http({
        url: "mling/api/resourceorder/orderCreate",
        data: params
    })
}
export function searchMember(params) {
    return http({
        url: "mling/api/member/searchmember",
        data: params
    })
}
export function invoiceConfig(params) {
    return http({
        url: "mling/api/config/invoiceconfig",
        data: params
    })
}
export function orderList(params) {
    return http({
        url: "mling/api/resourceorder/getOrderList",
        data: params
    })
}
export function orderStatus(params) {
    return http({
        url: "mling/api/resourceorder/getOrderStatus",
        data: params
    })
}
export function orderClose(params) {
    return http({
        url: "mling/api/resourceorder/orderClose",
        data: params
    })
}
export function confirmPrice(params) {
    return http({
        url: "mling/api/resourceorder/confirmPrice",
        data: params
    })
}
export function cartList(params) {
    return http({
        url: "mling/api/cart/page",
        data: params
    })
}
export function collectList(params) {
    return http({
        url: "mling/api/goodscollect/page",
        data: params
    })
}
export function footprintList(params) {
    return http({
        url: "mling/api/goodsbrowse/page",
        data: params
    })
}
export function addBrowse(params) {
    return http({
        url: "mling/api/goodsbrowse/add",
        data: params
    })
}
export function deleteBrowse(params) {
    return http({
        url: "mling/api/goodsbrowse/delete",
        data: params
    })
}
export function authInfo(params) {
    return http({
        url: "mling/api/Realnameauth/getInfo",
        data: params
    })
}
export function addAuthApply(params) {
    return http({
        url: "mling/api/Realnameauth/addApply",
        data: params
    })
}
export function editAuthApply(params) {
    return http({
        url: "mling/api/Realnameauth/editApply",
        data: params
    })
}
export function shopMemberDetail(params) {
    return http({
        url: "mling/api/Shopmember/getDetail",
        data: params
    })
}
export function modifyAutoRenew(params) {
    return http({
        url: "mling/api/Shopmember/modifyAutoRenew",
        data: params
    })
}
export function cancelApply(params) {
    return http({
        url: "mling/api/Shopmember/cancelApply",
        data: params
    })
}
export function cancelApplyRevoke(params) {
    return http({
        url: "mling/api/Shopmember/cancelApplyRevoke",
        data: params
    })
}
export function sellerInfo(params) {
    return http({
        url: "mling/api/seller/getSellerInfo",
        data: params
    })
}
export function sellerCategory(params) {
    return http({
        url: "mling/api/seller/getSellerCategory",
        data: params
    })
}
export function orderDetail(params) {
    return http({
        url: "mling/api/Resourceorder/getOrderDetail",
        data: params
    })
}
export function modifyDetailPrice(params) {
    return http({
        url: "mling/api/Resourceorder/modifyDetailPrice",
        data: params
    })
}
export function modifyPayMoneyType(params) {
    return http({
        url: "mling/api/Resourceorder/modifyPayMoneyType",
        data: params
    })
}
export function modifyInvoice(params) {
    return http({
        url: "mling/api/resourceorder/modifyInvoice",
        data: params
    })
}
export function modifyRemark(params) {
    return http({
        url: "mling/api/resourceorder/modifyRemark",
        data: params
    })
}
export function modifyDepositMoney(params) {
    return http({
        url: "mling/api/Resourceorder/modifyDepositMoney",
        data: params
    })
}
export function orderPay(params) {
    return http({
        url: "mling/api/Resourceorder/orderPay",
        data: params
    })
}
export function orderWechatPay(params) {
    return http({
        url: "mling/api/resourceorder/orderWechatPay",
        data: params
    })
}

export function getPayStatus(params) {
    return http({
        url: "mling/api/resourceorder/getPayStatus",
        data: params
    })
}
export function modifyCustomerAgent(params) {
    return http({
        url: "mling/api/resourceorder/modifyCustomerAgent",
        data: params
    })
}
export function orderAliPay(params) {
    return http({
        url: "mling/api/resourceorder/orderAliPay",
        data: params
    })
}
export function orderAddFile(params) {
    return http({
        url: "mling/api/Resourceorder/addFile",
        data: params
    })
}
export function orderDeleteFile(params) {
    return http({
        url: "mling/api/Resourceorder/deleteFile",
        data: params
    })
}
export function serviceComplete(params) {
    return http({
        url: "mling/api/Resourceorder/serviceComplete",
        data: params
    })
}
export function orderFinalPay(params) {
    return http({
        url: "mling/api/Resourceorder/orderFinalPay",
        data: params
    })
}
export function correctComplete(params) {
    return http({
        url: "mling/api/Resourceorder/correctComplete",
        data: params
    })
}
export function applyRefund(params) {
    return http({
        url: "mling/api/Resourceorder/applyRefund",
        data: params
    })
}
export function cancelRefund(params) {
    return http({
        url: "mling/api/Resourceorder/cancelRefund",
        data: params
    })
}
export function agreeRefund(params) {
    return http({
        url: "mling/api/Resourceorder/agreeRefund",
        data: params
    })
}
export function refuseRefund(params) {
    return http({
        url: "mling/api/Resourceorder/refuseRefund",
        data: params
    })
}
export function wechatLoginCode(params) {
    return http({
        url: "wechat/api/wechat/logincode",
        data: params
    })
}
export function checkWechatLogin(params) {
    return http({
        url: "mling/api/login/checkLogin",
        data: params
    })
}
export function wechatLogin(params) {
    return http({
        url: "mling/api/login/wechatLogin",
        data: params
    })
}
export function getChatGroupId(params) {
    return http({
        url: "mling/api/chat/getChatGroupId",
        data: params
    })
}
export function getChatGroupList(params) {
    return http({
        url: "mling/api/chat/getChatGroupList",
        data: params
    })
}
export function getChatMessageList(params) {
    return http({
        url: "mling/api/chat/getChatMessageList",
        data: params
    })
}
export function online(params) {
    return http({
        url: "mling/api/chat/online",
        data: params
    })
}
export function offline(params) {
    return http({
        url: "mling/api/chat/offline",
        data: params
    })
}
export function sendMessage(params) {
    return http({
        url: "mling/api/chat/sendMessage",
        data: params
    })
}
export function readMessage(params) {
    return http({
        url: "mling/api/chat/readMessage",
        data: params
    })
}
export function downloadInfo(params) {
    return http({
        url: "mling/api/app/downloadInfo",
        data: params
    })
}
export function hotcategory(params) {
    return http({
        url: "mling/api/config/hotcategory",
        data: params
    })
}
export function memberAccount(params) {
    return http({
        url: "mling/api/memberaccount/page",
        data: params
    })
}
export function goodsEstimateLis(params) {
    return http({
        url: "mling/api/Goodsestimate/getGoodsEstimateList",
        data: params
    })
}
export function memberDetail(params) {
    return http({
        url: "mling/api/member/detail",
        data: params
    })
}
export function bindmobliecode(params) {
    return http({
        url: "mling/api/member/bindmobliecode",
        data: params
    })
}
export function modifyMultiInfo(params) {
    return http({
        url: "mling/api/member/modifyMultiInfo",
        data: params
    })
}
export function selectGoodsTender(params) {
    return http({
        url: "mling/api/goodstender/selectGoodsTender",
        data: params
    })
}
export function sellerGoodsTenderList(params) {
    return http({
        url: "mling/api/goodstender/getSellerGoodsTenderList",
        data: params
    })
}
export function rechargeType(params) {
    return http({
        url: "api/pay/type",
        data: params
    })
}
export function createRecharge(params) {
    return http({
        url: "memberrecharge/api/Ordercreate/create",
        data: params
    })
}
export function rechargePay(params) {
    return http({
        url: "api/pay/pay",
        data: params
    })
}
export function rechargeStatus(params) {
    return http({
        url: "api/pay/status",
        data: params
    })
}
export function base64Qrcode(params) {
    return http({
        url: "mling/api/Util/base64Qrcode",
        data: params
    })
}
export function withdrawConfig(params) {
    return http({
        url: "api/Memberwithdraw/config",
        data: params
    })
}
export function memberAccountList(params) {
    return http({
        url: "api/Memberbankaccount/page",
        data: params
    })
}
export function deleteMemberAccount(params) {
    return http({
        url: "api/Memberbankaccount/delete",
        data: params
    })
}
export function applyWithdraw(params) {
    return http({
        url: "api/Memberwithdraw/apply",
        data: params
    })
}
export function accountType(params) {
    return http({
        url: "api/Memberwithdraw/transferType",
        data: params
    })
}
export function addAccount(params) {
    return http({
        url: "api/Memberbankaccount/add",
        data: params
    })
}
export function orderCommissionList(params) {
    return http({
        url: "mling/api/Resourceorder/getOrderCommissionList",
        data: params
    })
}
export function commissionWithdraw(params) {
    return http({
        url: "mling/api/Memberwithdraw/apply",
        data: params
    })
}
export function exportOrderCommissionList(params) {
    return http({
        url: "mling/api/Resourceorder/exportOrderCommissionList",
        data: params
    })
}
